.container-2 {
  position: absolute;
  left: 0;
  top: 13%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 82.5%;
}

.bg-container-white {
  background: white;
}

.bg-big-picture {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  background-position: center;
  overflow-x: hidden;
}

.container-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
}

.container-logo {
  width: 100%;
  height: 13%;
  padding-top: 1.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-airbus-fixed {
  position: fixed;
  left: 1%;
  bottom: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1
}

.container-airbus-fixed img {
  height: 40px;
  margin: 40px;
}

.container-airbus img {
  height: 40px;
  margin: 40px;
}


.container-airbus {
  padding-right: 1%;
  padding-bottom: 1%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1
}

.main-wrapped {
  background: white;
  border: 2px solid #D9D9D9;
  border-radius: 4px;
  padding: 70px 100px;
  min-height: 700px;
  min-width: 500px;
  position: relative;
}

.App-heading {
  margin-top: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-div {
  background: white;
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 1850px;
}

@media (max-width: 830px) {
  .App-div {
    padding: 50px 20px;
  }
}

@media (min-width: 830px) {
  .App-div {
    padding: 70px 70px;
  }
}

@media (min-width: 1250px) {
  .App-div {
    padding: 100px 250px;
  }
}

.h1 {
  color: #5D5A59;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 36px;
}

.h1 p {
  text-align: justify;
  margin: 0px;
}

.body1 {
  color: #5D5A59;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.subtitle1 {
  color: #5D5A59;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
}

.join-media {
  color: #8C8C8C;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--primary);
  margin-bottom: 8px;
}

.input {
  border: solid 2px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  padding: 15px;
  background: #F8F8F8;
  max-width: 298px;
  max-height: 160px;
  color: #5D5A59;
  font-weight: 400;
}

.textarea-description {
  border: solid 2px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  padding: 15px;
  min-height: 115px;
  background: #F8F8F8;
  max-width: 100%;
  max-height: 160px;
  color: #5D5A59;
  font-weight: 400;
  min-width: 191px;
}

.dropzone-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
}

.dropzone-subtitle {
  color: #5D5A59;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
}

.media-detail-box {
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 6px 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-error-box {
  background: #F7EBEB;
  border: solid 1px #D14139;
  color: #D14139;
  border-radius: 4px;
  padding: 11px 14px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-and-icon {
  display: flex;
  align-items: center;
}

.media-detail-typo {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.media-error-typo {
  color: #D14139;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 40px;
}

.mr-0 {
  margin: 0;
}

.mr-05 {
  margin-right: 4px;
}

.mr-1 {
  margin-right: 8px;
}

.centred {
  text-align: center;
}

.drop-div {
  margin-bottom: 45px;
}

.bottom-upload {
  display: flex;
  flex-direction: column;
}

.slider-mui {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-be {
  display: flex;
}

.close-icon {
  cursor: pointer;
}

.copy-icon {
  height: 32px;
  padding: 4px;
  border-radius: 26px;
  cursor: pointer;
}

.copy-icon:hover {
  background: rgba(0, 0, 0, 0.04);
}

.close-icon:hover {
  background: rgba(0, 0, 0, 0.18);
  border-radius: 20px;
}

.div-height {
  max-height: 480px;
  overflow-y: auto;
}

.link-to {
  text-decoration-line: underline;
  color: #5D5A59;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
}

.copy-link {
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--primary);
}

.sp-success-container {
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sp-login-container {
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center
}